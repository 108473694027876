export const tags = [
  {
    id: 'web-application',
    name: 'Веб-приложение',
  },
  {
    id: 'mobile-application',
    name: 'Мобильное приложение',
  },
  {
    id: 'identity',
    name: 'Айдентика',
  },
  {
    id: 'data-visualization',
    name: 'Визуализация данных',
  },
  {
    id: 'illustrations',
    name: 'Иллюстрации',
  },
  {
    id: 'react',
    name: 'React',
  },
  {
    id: 'react-native',
    name: 'React Native',
  },
  {
    id: 'redux',
    name: 'Redux',
  },
  {
    id: 'd3',
    name: 'D3',
  },
  {
    id: 'gitlabCi',
    name: 'Gitlab ci',
  },
  {
    id: 'web-design',
    name: 'Веб-дизайн',
  },
  {
    id: 'layout',
    name: 'Верстка',
  },
  {
    id: 'backend',
    name: 'Бекенд',
  },
  {
    id: 'angular',
    name: 'Angular',
  }
];
