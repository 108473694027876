import { useState, useLayoutEffect } from 'react';
import { useWindowThresholdScroll } from './useWindowScroll';

const useCurrentPoint = (ref) => {
  const [point, setPoint] = useState(0);

  useLayoutEffect(() => {
    const currentPoint = () => {
      if (ref && ref.current) {
        return ref.current?.getBoundingClientRect().y;
      }
    }

    setPoint(currentPoint() + (window.scrollY - window.innerHeight + 100));
  }, [ref, point])

  const pointIsScrolled = useWindowThresholdScroll(point)

  return pointIsScrolled;
}

export default useCurrentPoint;
