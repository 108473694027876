import ruslan from '../images/teamPhoto/ruslan.jpg';
import yuri from '../images/teamPhoto/yuri.jpg';
import varvara from '../images/teamPhoto/varvara.jpg';
import aleksandr from '../images/teamPhoto/aleksandr.jpg';
import andrey from '../images/teamPhoto/andrey.jpg';
import anastasia from '../images/teamPhoto/anastasia.jpg';

import ruslan_webp from '../images/teamPhoto/ruslan.webp';
import yuri_webp from '../images/teamPhoto/yuri.webp';
import varvara_webp from '../images/teamPhoto/varvara.webp';
import aleksandr_webp from '../images/teamPhoto/aleksandr.webp';
import andrey_webp from '../images/teamPhoto/andrey.webp';
import anastasia_webp from '../images/teamPhoto/anastasia.webp';

import ruslan_jp2 from '../images/teamPhoto/ruslan.jp2';
import yuri_jp2 from '../images/teamPhoto/yuri.jp2';
import varvara_jp2 from '../images/teamPhoto/varvara.jp2';
import aleksandr_jp2 from '../images/teamPhoto/aleksandr.jp2';
import andrey_jp2 from '../images/teamPhoto/andrey.jp2';
import anastasia_jp2 from '../images/teamPhoto/anastasia.jp2';

export const teamList = [
  {
    name: 'Юрий',
    photo: {
      webp: yuri_webp,
      jp2: yuri_jp2,
      jpg: yuri,
    },
    workingPosition: ['founder', 'project manager', 'fullstack developer'],
  },
  {
    name: 'Варвара',
    photo: {
      webp: varvara_webp,
      jp2: varvara_jp2,
      jpg: varvara,
    },
    workingPosition: ['founder', 'designer'],
  },
  {
    name: 'Александр',
    photo: {
      webp: aleksandr_webp,
      jp2: aleksandr_jp2,
      jpg: aleksandr,
    },
    workingPosition: ['devops'],
  },
  {
    name: 'Руслан',
    photo: {
      webp: ruslan_webp,
      jp2: ruslan_jp2,
      jpg: ruslan,
    },
    workingPosition: ['frontend developer'],
  },
  {
    name: 'Андрей',
    photo: {
      webp: andrey_webp,
      jp2: andrey_jp2,
      jpg: andrey,
    },
    workingPosition: ['backend developer'],
  },
  {
    name: 'Анастасия',
    photo: {
      webp: anastasia_webp,
      jp2: anastasia_jp2,
      jpg: anastasia,
    },
    workingPosition: ['QA'],
  },
]