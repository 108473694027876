import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import MainPage from './MainPage';
// import useWindowScroll from '../hooks/useWindowScroll';
import projectList from '../data/projectList';
import SmoothScroll from 'smooth-scroll';
import {tags} from '../data/tags'

const filterBySelectedTag = (selectedTag) => {
  if (!selectedTag) return projectList;

  const filterProjectList = projectList.filter((item) => {
    const filterTag = item.tags.filter((tag) => tag === selectedTag);

    return filterTag.length !== 0;
  });

  return filterProjectList;
}

new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
  // header: '.main_header',
  easing: 'easeInOutQuad',
  offset: 100,
});

export const getMainTags = () => {
  const needTag = [
    'web-application',
    'mobile-application',
    'identity',
    'data-visualization',
    'illustrations',
    'web-design'
  ];

  const newTagsList = tags.filter((items) => needTag.find((tag) => items.id === tag));

  return newTagsList;
};

const SmartMainPage = () => {

  const { selectedTag } = useParams();

  const filteredProjectList = useMemo(() => {
    return filterBySelectedTag(selectedTag)
  }, [selectedTag]);

  // const worksListId =  useMemo(() => refMainContent.current?.querySelector('#works-list'), []);

  return (
    <MainPage
      tagsList={getMainTags()}
      filteredProjectList={filteredProjectList}
    />
  )
}

export default SmartMainPage;
