import React, { useState } from 'react';
import DropdownContact from '../../DropdownContact/DropdownContact';
import cn from 'classnames';

import './style.css';
// import { useParams } from 'react-router-dom';

const Header = ({ sizeHeader }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const hideDropdownOrMobMenu = () => {
    if (showDropdown) {
      setShowDropdown(false);
    }
    if (showMobileMenu) {
      setShowMobileMenu(false);
    }
  }

  return (
    <div className="main_header__wrapper">
      <header id="main-header" className={cn('main_header', {'size-header': sizeHeader, 'hide-header': showDropdown})}>
        <a className="main_header__logo" href="/">Wondercreatures</a>
        <a className="main_header__logo mobile" href="/">Wonder creatures</a>
        <button
          type="button"
          className={
            cn('hamburger-button', {show: showMobileMenu})
            }
          onClick={() => setShowMobileMenu(!showMobileMenu)}
          >
          <div className="hamburger-button__item_top"></div>
          <div className="hamburger-button__item_mid"></div>
          <div className="hamburger-button__item_bottom"></div>
        </button>
        <nav className="main_header__navigation">
          <a className="main_header__navigation__link" href="/#works-list" onClick={hideDropdownOrMobMenu}>Работы</a>
          <a className="main_header__navigation__link" href="/#work-team" onClick={hideDropdownOrMobMenu}>Команда</a>
          <a className="main_header__navigation__link" href="#contacts" onClick={hideDropdownOrMobMenu}>Контакты</a>
        </nav>
        <button
          type="button"
          className="main_header__navigation__button-contact"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          Связаться
        </button>
      </header>
      <DropdownContact showDropdown={showDropdown} onShowDropdown={() => setShowDropdown(false)} />
    </div>
  );
};

export default Header;
