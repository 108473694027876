import styles from "./LaconicPage.module.css";
import cn from "classnames";

import LaconicLogo from "../../images/project_images/laconic/button-blocks/logo.svg";
import EyeIcon from "../../images/project_images/laconic/button-blocks/eye_icon.svg";
import AppstoreIcon from "../../images/project_images/laconic/button-blocks/appstore_icon.svg";
import HandWithPhoneImage from "../../images/project_images/laconic/button-blocks/handphone.png";
import HandWithPhoneImageMobile from "../../images/project_images/laconic/button-blocks/handphone_without_words.png";
import HandWithPhoneImageMobileSmall from "../../images/project_images/laconic/button-blocks/handphone_without_words_mobile.png";
import HandWithPhoneImage2 from "../../images/project_images/laconic/button-blocks/handphone_2.png";
import HandWithPhoneImage2Mobile from "../../images/project_images/laconic/button-blocks/handphone_2_without_words.png";
import HandWithPhoneImage2MobileSmall from "../../images/project_images/laconic/button-blocks/handphone_2_without_words_mobile.png";
import ScreensImage from "../../images/project_images/laconic/button-blocks/screens.png";
import ScreensImageMobile from "../../images/project_images/laconic/button-blocks/screens_mobile.png";
import ScreensImageMobileSmall from "../../images/project_images/laconic/button-blocks/screens_mobile_small.png";
import LightDarkThemes from "../../images/project_images/laconic/button-blocks/lightdarkthemes.png";
import LightDarkThemesMobile from "../../images/project_images/laconic/button-blocks/lightdarkthemes_mobile.png";
import QuotemanLarge from "../../images/project_images/laconic/button-blocks/quoteman_large.svg";
import QuotemanSmall from "../../images/project_images/laconic/button-blocks/quoteman_small.svg";

const ButtonsBlock = ({ isBottom = false }) => (
  <div
    className={cn(styles.firstContainer__content__buttons, {
      [styles.reverseButtons]: isBottom,
    })}
  >
    <a
      href="https://apps.apple.com/us/app/id1560841729"
      target="_blank"
      rel="noreferrer"
      className={styles.firstContainer__content__appstoreButton}
    >
      <img alt="" src={AppstoreIcon} />
    </a>
    <a
      target="_blank"
      rel="noreferrer"
      href="https://laconic.so/"
      className={styles.firstContainer__content__button}
    >
      <img alt="" src={EyeIcon} />
      <span>Visit site</span>
    </a>
  </div>
);

export default function LaconicUpperButtonBlock() {
  return (
    <div className={styles.container}>
      <div className={styles.firstContainer}>
        <div className={styles.firstContainer__handImageContainer}>
          <img
            className={styles.firstContainer__handImageContainer__image}
            alt=""
            src={HandWithPhoneImage}
          />
          <img
            className={styles.firstContainer__handImageContainer__image2}
            alt=""
            src={HandWithPhoneImageMobile}
          />
        </div>
        <div className={styles.firstContainer__content}>
          <img className={styles.logo} alt="" src={LaconicLogo} />
          <div className={styles.firstContainer__content__mobileImageContainer}>
            <img
              className={styles.firstContainer__content__mobileImage}
              alt=""
              src={HandWithPhoneImageMobileSmall}
            />
          </div>
          <h3 className={styles.firstContainer__content__title}>
            Remember everything you read
          </h3>
          <p className={styles.firstContainer__content__text}>
            Use Laconic to scan quotes from paper books, organize your thoughts
            and ideas
          </p>
          <ButtonsBlock />
        </div>
      </div>
      <div className={styles.secondContainer}>
        <div className={styles.secondContainer__content}>
          <h3 className={styles.firstContainer__content__title}>
            Organize your ideas and quotes beautifully
          </h3>
          <p className={styles.firstContainer__content__text}>
            Use tags, stickers and powerful formatting capabilities to make your
            own summary
          </p>
        </div>
        <div className={styles.secondContainer__handImageContainer}>
          <img
            className={styles.firstContainer__handImageContainer__image}
            alt=""
            src={HandWithPhoneImage2}
          />
          <img
            className={styles.secondContainer__handImageContainer__imageMobile}
            alt=""
            src={HandWithPhoneImage2Mobile}
          />
          <img
            className={
              styles.secondContainer__handImageContainer__imageMobileSmall
            }
            alt=""
            src={HandWithPhoneImage2MobileSmall}
          />
        </div>
      </div>
      <div className={styles.screens}>
        <img
          className={styles.firstContainer__handImageContainer__image}
          alt=""
          src={ScreensImage}
        />
        <img
          className={styles.screens_screensImageMobile}
          alt=""
          src={ScreensImageMobile}
        />
        <img
          className={styles.screens_screensImageMobileSmall}
          alt=""
          src={ScreensImageMobileSmall}
        />
      </div>
      <div className={styles.thirdContainer}>
        <div className={styles.thirdContainer__content}>
          <h3 className={styles.firstContainer__content__title}>
            Keep your reading accountable
          </h3>
          <p className={styles.firstContainer__content__text}>
            Use Laconic to see what books you are reading now or read before
          </p>
        </div>
        <div className={styles.thirdContainer__themesImageContainer}>
          <img
            className={cn(
              styles.firstContainer__handImageContainer__image,
              styles.thirdContainer__image
            )}
            alt=""
            src={LightDarkThemes}
          />
          <img
            className={styles.thirdContainer__imageMobile}
            alt=""
            src={LightDarkThemesMobile}
          />
        </div>
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.bottomContainer__imageContainer}>
          <img
            className={cn(
              styles.firstContainer__handImageContainer__image,
              styles.bottomContainer__image
            )}
            alt=""
            src={QuotemanLarge}
          />
        </div>
        <div className={styles.bottomContainer__content}>
          <h3 className={styles.firstContainer__content__title}>
            Hope you’ll enjoy Laconic
          </h3>
          <ButtonsBlock />
        </div>
      </div>
      <div className={styles.bottomContainerMobile}>
        <h3 className={styles.firstContainer__content__title}>
          Hope you’ll enjoy Laconic
        </h3>
        <div className={styles.bottomContainerMobile__content}>
          <ButtonsBlock isBottom />
          <img className={styles.bottomContainerMobile__content__image} alt="" src={QuotemanSmall} />
        </div>
      </div>
    </div>
  );
}
