import React, { useRef, useState } from 'react';
import { teamList } from './teamList';
// import cn from 'classnames';

import './style.css';
import useCurrentFromRefPoint from '../hooks/useCurrentPoint';

const WorkingGroup = () => {
  const [photoLoaded, setPhotoLoaded] = useState(false);

  const titleRef = useRef();

  const itemRef = useRef();

  const pointItemIsVisible = useCurrentFromRefPoint(itemRef);
  const pointTitleIsVisible = useCurrentFromRefPoint(titleRef)

  return (
    <div className="working-group" id="work-team">
      <h2 className="working-group__title" ref={titleRef} style={{animationPlayState: pointTitleIsVisible ? 'running' : 'paused'}}>Вандертим</h2>
      <div className="working-group__list">
        {teamList.map((item, n) => (
          <div key={n} className="working-group__list__item" style={{animationPlayState: pointItemIsVisible ? 'running' : 'paused'}} ref={itemRef}>
            <picture style={{backgroundColor: !photoLoaded && '#E9E9E9'}} className="working-group__list__item__photo-wrap">
              <source
                srcSet={item.photo.webp}
                type="image/webp"
              />
              <source
                srcSet={item.photo.jp2}
                type="image/jp2"
              />
              <img
                src={item.photo.jpg}
                alt="photoTeam"
                className="working-group__list__item__photo"
                width="600"
                height="600"
                style={{opacity: !photoLoaded && 0, backgroundColor: 'transparent'}}
                onLoad={() => setPhotoLoaded(true)}
              />
            </picture>
            <ul className="working-group__list__working-position">
              <span className="working-group__list__item__name">{item.name}</span>
              {/* wp - working position */}
              {item.workingPosition.map((wp, key) => (
                <li key={key} className="working-group__list__item__position">{wp}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WorkingGroup;
