import { useState, useLayoutEffect, useEffect } from 'react';
import throttle from 'lodash.throttle';

const browser = window;



// const needUpdateScroll = (HEADER_OFFSET, oldScroll, newScroll) => {
//     if (oldScroll < HEADER_OFFSET && newScroll >= HEADER_OFFSET) {
//       console.log('nize')
//       console.log(oldScroll, newScroll);
//       return true;
//     } else if (oldScroll >= HEADER_OFFSET && newScroll < HEADER_OFFSET) {
//       console.log('vishe')
//       console.log(oldScroll, newScroll);
//       return true;
//     } else {
//       return false;
//     }
//   }
  

export function useWindowThresholdScroll (thresholdValue = 0) {
  const [pointScrolled, setPointScrolled] = useState(false);

  useEffect(() => {
    // const updateScroll = throttle(() => {
    //   const scrollVal = browser.scrollY;
    //   if (needUpdateScroll(20, scroll, scrollVal)) {
    //     setScroll(scrollVal)
    //   }
    //   if (scrollVal > thresholdValue) {
    //     // console.log('scrollY', scrollVal)
        
    //   }
    // }, 100);

    const updateScroll = () => {
      if (browser.scrollY >= thresholdValue && !pointScrolled) {
        setPointScrolled(true);
      }
      if (browser.scrollY < thresholdValue && pointScrolled) {
        setPointScrolled(false);
      }
    }

    browser.addEventListener('scroll', updateScroll, {passive: true});
    return () => browser.removeEventListener('scroll', updateScroll);
  }, [pointScrolled, thresholdValue])

  // console.log(pointScrolled)
  
  return pointScrolled;
}

export default function useWindowScroll() {
  const [scroll, setScroll] = useState(0);


  useLayoutEffect(() => {
    const updateScroll = throttle(() => {
      // console.log('updateScroll---');
      setScroll(browser.scrollY);
    }, 100);

    browser.addEventListener('scroll', () => {
      // setTimeout(() => updateScroll(), 10);

      updateScroll()
    }, {passive: true});
    return () => browser.removeEventListener('scroll', updateScroll);
  }, [setScroll])
  
  return scroll;
}

export const isCurrentYPosition = (position, scrollCurrent) => position >= scrollCurrent;
