import React from 'react';
import ButtonScrollUp from '../FieldEntity/ButtonScrollUp';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import cn from 'classnames';
import './style.css'
import { useWindowThresholdScroll } from '../hooks/useWindowScroll';


const HEADER_OFFSET = 20;

const LayoutWrapper = ({ children }) => {
  const pointIsScrolled = useWindowThresholdScroll(HEADER_OFFSET);

  return (
    <div className={cn('layout_page', {scroll: pointIsScrolled})}>
      {children}
    </div>
  )
}

const Layout = ({ children, sizeHeader }) => {

  return (
    <LayoutWrapper>
      <Header sizeHeader={sizeHeader} />
      <ButtonScrollUp />
      {children}
      <Footer />
    </LayoutWrapper>
  );
};

export default Layout;
