import React from 'react';
import { 
  Switch,
  Route
} from 'react-router-dom';
import ScrollRouterToTop from './hooks/ScrollRouterToTop';
import PageProject from './PageProject/PageProject';
import Layout from './Layout'
import SmartMainPage from './MainPage/SmartMainPage';

const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact>
        <Layout sizeHeader>
          <SmartMainPage />
        </Layout>
      </Route>
      <Route path="/:selectedTag" exact>
        <Layout sizeHeader>
          <SmartMainPage />
        </Layout>
      </Route>
      <Route path="/project/:project" exact>
        <ScrollRouterToTop />
        <Layout>
          <PageProject />
        </Layout>
      </Route>
    </Switch>
  );
};

export default Routes;
