import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import axios from "axios"

import './style.css';
import CallbackForm from './CallbackForm';
import Notification from './Notification';
import { REACT_BACKEND_URL } from '../config';

const DropdownContact = ({ showDropdown, onShowDropdown }) => {
  const [name, setName] = useState('');
  const [contact, setContact] = useState('');
  const [message, setMessage] = useState('');
  const [messageSent, setMessageSent] = useState(false);
  
  const removeValues = () => {
    setName('');
    setContact('');
    setMessage('');
  }

  const submitMessage = (e) => {
    e.preventDefault();
    axios.request(
      {
        method: 'POST',
        url: `${REACT_BACKEND_URL}/send-feedback`,
        data: {
          name,
          contact,
          message,
        },
      }, [200],
    )
    .then((response) => {
      console.log(response);
      removeValues();
      setMessageSent(true)
    })
  }

  const hideDropdownAndNotification = () => {
    onShowDropdown();
    setMessageSent(false);
  }

  useEffect(() => {
    if (!showDropdown) {
      setMessageSent(false);
    }
  }, [showDropdown])

  return (
    <div className={cn("dropdown-contact_container", { 'show': showDropdown})}>
      {!messageSent ? (
        <CallbackForm 
          name={name}
          contact={contact}
          msg={message}
          submitMessage={submitMessage}
          onChangeName={setName}
          onChangeContact={setContact}
          onChangeMSG={setMessage}
          onShowDropdown={onShowDropdown}
        />
      ) : (
        <Notification
          onShowDropdown={hideDropdownAndNotification}
        />
      )}
    </div>
  )
}

export default DropdownContact;
