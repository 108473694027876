import FullPigSVG from "../images/wonderPig.svg";
import PigSVG from "../images/pig-project.svg";

export const pigWannaFly = (ref) => {
  ref.current.src = FullPigSVG;
  ref.current.classList.add("pig-image-fly");

  setTimeout(() => {
    if (ref.current) {
      ref.current.src = PigSVG;
      ref.current.classList.remove("pig-image-fly");
    }
  }, 5000);
};
