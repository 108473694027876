import React from 'react'
import wonderPig from '../images/wonderPig.svg';

const Notification = ({
  onShowDropdown,
}) => {
  return (
    <div className="notification">
      <img src={wonderPig} className="notification__img" alt="Pig" />
      <h3 className="notification__title">Спасибо!</h3>
      <span className="notification__description">Скоро ответим на вашу заявку.</span>
      <button className="notification__button" onClick={onShowDropdown}>Пожалуйста</button>
    </div>
  );
};

export default Notification;
