import React, { useRef } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom'

import './tagsList.css';
import { useParams } from 'react-router-dom';
import SmothScroll from 'smooth-scroll';
import useCurrentFromRefPoint from '../hooks/useCurrentPoint';

const scroll = new SmothScroll();

const FilterProjectsList = ({ tagsList, isScrolled, refWorksList }) => {
  const { selectedTag } = useParams();

  const isTrueSelectedTag = (tagId) => selectedTag === tagId;

  const urlTag = (tagId) => {
    const isTrueSelected = isTrueSelectedTag(tagId);

    const url = isTrueSelected ? '/' : `/${tagId}`;

    return url;
  }

  const smothScrollToWorksList = () => {
    scroll.animateScroll(refWorksList.current)
  }

  const refList = useRef();

  const pointIsVisible = useCurrentFromRefPoint(refList);

  // console.log('FilterProjectList-----')

  return (
    <div className="tags-list" style={{animationPlayState: pointIsVisible ? 'running' : 'paused'}} id="tags-list-filter" ref={refList}>
      {tagsList.map((item, n) => (
        <Link
          className={
            cn(
              'tags-list__item', 
              {
                selected: isTrueSelectedTag(item.id),
              })}
          to={urlTag(item.id)}
          key={n}
          onClick={smothScrollToWorksList}
        >
          {item.name}
        </Link>
      ))}
    </div>
  );
};

export default FilterProjectsList;
