import React, { useRef } from 'react';
import FilterProjectsList from './FilterProjectsList';
import WorkingGroup from '../WorkingGroup';
import ProjectsList from '../ProjectsList';
import './style.css';
// import cn from 'classnames';
import { useWindowThresholdScroll } from '../hooks/useWindowScroll';
import useCurrentFromRefPoint from '../hooks/useCurrentPoint';
import { pigWannaFly } from '../helpers/pigWannaFly';

import PigSVG from '../images/pig-project.svg';

export const InViewHoc = (refMainContent, indent = 200, WrappedComponent) => {

  const elementRef = useRef();

  const comparePositioning = () => {
    if (refMainContent && refMainContent.current) {
      const currentPoint = elementRef.current.getBoundingClientRect().y;

      const positionFromTop = (window.scrollY + currentPoint) - window.innerHeight + indent;

      return positionFromTop;
    }
  }

  const pointFromTop = comparePositioning();

  const pointIsScrolled = useWindowThresholdScroll(pointFromTop);

  return (<WrappedComponent inView={pointIsScrolled} ref={elementRef} />);
};


const WonderHeader = () => {
  const refTitleWorks = useRef();

  const pointIsVisible = useCurrentFromRefPoint(refTitleWorks)

  return (
    <h2
      id={"works-list"}
      ref={refTitleWorks}
      className="main-page__title_works main-page__title"
      style={{animationPlayState: pointIsVisible ? 'running' : 'paused'}}
    >
      Вандерворкс
    </h2>
  );
};

// <h2 id={"works-list"}
//   className={cn('main-page__title_works main-page__title',
//     {
//       showed: onComparePositioning('#works-list', true),
//     })}
//   >
//     Вандерворкс
// </h2>
// <FilterProjectsList
//   tagsList={tagsList}
//   isScrolled={onComparePositioning('#main-page__works-list')}
//   worksListId={worksListId}
// />


// const ProjectsHeader = ({ refMainContent, tagsList, refWorksList }) => {
//   console.log('render projects header')
//   return (<>
//     {InViewHoc(refMainContent, 200, WonderHeader)}
//     {/* {InViewHoc(refMainContent, 200, forwardRef(({ inView }, ref) => (
//       <FilterProjectsList
//         refEl={ref}
//         tagsList={tagsList}
//         isScrolled={inView}
//         refWorksList={refWorksList}
//       />
//     )))} */}
//   </>);
// }



const MainPage = ({
  tagsList,
  // worksListId,
  filteredProjectList,
}) => {
  const refWorksList = useRef();
  const pigRef = useRef();

  const refMainContent = useRef();

  return (
    <main className="content-container main-page" ref={refMainContent}>
      <img ref={pigRef} className="main-page__pig-image pig-image-reset-animation" alt="" src={PigSVG} onClick={() => pigWannaFly(pigRef)} />
      <ul className="main-page__list-descriptions">
        <li className="main-page__description">
          Разрабатываем дизайн
        </li>
        <li className="main-page__description">
          Пишем код
        </li>
        <li className="main-page__description">
          Любим тесты <br />и автоматизацию
        </li>
        <li className="main-page__description">
          Делаем сложные веб-интерфейсы <br />и визуализируем данные
        </li>
      </ul>
      <div id="main-page__works-list" className="main-page__works-list" ref={refWorksList} >
        {/* <ProjectsHeader refMainContent={refMainContent} tagsList={tagsList} refWorksList={refWorksList} /> */}
        <WonderHeader />
        <FilterProjectsList tagsList={tagsList} refWorksList={refWorksList} />
        <ProjectsList itemsList={filteredProjectList} mainPage />
      </div>
      <WorkingGroup />
    </main>
  );
};

export default MainPage;
