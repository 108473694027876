import React, { useRef } from 'react';
import useCurrentFromRefPoint from '../../hooks/useCurrentPoint';
import { teamList } from '../../WorkingGroup/teamList';
// import cn from 'classnames'
import './style.css';

const getPeopleItems = (name) => teamList.find((item) => item.name === name);

const yusnk = getPeopleItems('Юрий');
const vundyne = getPeopleItems('Варвара');

const Photo = ({
  item,
}) => {
  return (
    <picture className="main-footer__link-contact_telegram__photo_wrapper">
      <source
        srcSet={item.photo.webp}
        type="image/webp"
      />
      <source
        srcSet={item.photo.jp2}
        type="image/jp2"
      />
      <img
        src={item.photo.jpg}
        alt="photoTeam"
        className="main-footer__link-contact_telegram__photo"
        width="80"
        height="80"
      />
    </picture>
  )
}

const Footer = () => {
  const refFooter = useRef();

  const footerIsView = useCurrentFromRefPoint(refFooter);

  return (
    <footer
      className="main_footer"
      style={{
        animationPlayState: footerIsView ? 'running' : 'paused',
      }}
      ref={refFooter}
    >
      <h2 id="contacts" className="main-footer__title-contact">Контакты</h2>
      <a href="mailto:CONTACT@WONDERCREATURES.COM" className="main-footer__link-contact">CONTACT@WONDERCREATURES.COM</a>
      <div className="main-footer__contact-telegram_wrapper">
        <a
          href="https://t.me/yusnk"
          className="main-footer__link-contact main-footer__link-contact_telegram"
        >
          <Photo item={yusnk} />
          <div className="main-footer__link-contact_telegram__description">
            <span className="main-footer__link-contact_telegram__url">
              t.me/yusnk
            </span>
            <span className="main-footer__link-contact_telegram__work-post">
              project manager, fullstack developer
            </span>
          </div>
        </a>
        <a
          href="https://t.me/vundyne"
          className="main-footer__link-contact main-footer__link-contact_telegram"
        >
          <Photo item={vundyne} />
          <div className="main-footer__link-contact_telegram__description">
            <span className="main-footer__link-contact_telegram__url">
              t.me/vundyne
            </span>
            <span className="main-footer__link-contact_telegram__work-post">
              designer
            </span>
          </div>
        </a>
      </div>
      <span className="main_footer__copyright">{`© Wondercreatures, ${new Date().getFullYear()}`}</span>
    </footer>
  )
}

export default Footer;
