import React from 'react';
import useWindowScroll from '../hooks/useWindowScroll';
import cn from 'classnames';
import './style.css';

const ButtonScrollUp = () => {
  const windowScroll = useWindowScroll();
  
  const isShowButton = windowScroll > (window.innerHeight);

  return (
    <a
      href="#main-header"
      className={
        cn('button-scroll-up', {show: isShowButton})
      }
    >{}</a>
  )
}

export default ButtonScrollUp;
