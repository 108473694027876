import React, { useState, useRef } from "react";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";
import projectList from "../data/projectList";
import ImageContainer from "../FieldEntity/ImageContainer";
import OtherProjectList from "../OtherProjectList/OtherProjectList";
import "./style.css";
import cn from "classnames";
import { pigWannaFly } from "../helpers/pigWannaFly";

import PigSVG from '../images/pig-project.svg';

import LaconicPageComponent from "./Laconic/LaconicPageComponent";
import taviGif from "../images/project_images/tavi/gif/tavi_dance.gif";
import { tags } from "../data/tags";

const projectTags = (currentTags) => {
  const newTagsList = tags.filter((items) =>
    currentTags.find((tag) => items.id === tag)
  );

  return newTagsList;
};

const PageProject = () => {
  const [videoReady, setVideoReady] = useState(false);
  const { project } = useParams();
  const pigRef = useRef();

  const projectItems = projectList.find((item) => item.id === project);

  const { normal, large, small } = projectItems.mainImage;

  const tagsProject = projectTags(projectItems.tags);

  return (
    <main className="page-project">
      <img ref={pigRef} className="page-project__pig-image pig-image-reset-animation" alt="" src={PigSVG} onClick={() => pigWannaFly(pigRef)} />
      <h1 className="page-project__title">{projectItems.name}</h1>
      <p className="page-project__description">{projectItems.description}</p>
      <div className="page-project__tags">
        {projectItems.linkToApp &&
          Object.values(projectItems.linkToApp).map((link) => (
            <a
              className="page-project__tag page-project__link page-project__store-link"
              href={link}
              target="_blank"
              rel="noreferrer"
            >{`Скачать из ${
              link.includes("apple") ? "Appstore" : "Google Play"
            }`}</a>
          ))}
        {projectItems.link && (
          <a
            className="page-project__tag page-project__link"
            href={projectItems.link}
            target="_blank"
            rel="noreferrer"
          >
            Перейти на сайт
          </a>
        )}
        {tagsProject.map((item) => (
          <div className="page-project__tag" key={item.id}>
            {item.name}
          </div>
        ))}
      </div>
      <div className="page-project__main-image_wrapper">
        {projectItems.name === "Laconic" ? (
          <LaconicPageComponent />
        ) : (
          <ImageContainer
            largeImage={large}
            image={normal}
            smallImage={small}
            mobileImages={projectItems.mainImageMobile}
            imageAltName={projectItems.name}
            gradient={projectItems.gradient}
          />
        )}
        {projectItems.videoUrl && (
          <div
            className={cn("page-project__video_wrapper", { play: videoReady })}
            style={{ opacity: videoReady ? 1 : 0 }}
          >
            <ReactPlayer
              url={projectItems.videoUrl}
              className="page-project__react-player"
              muted
              width="100%"
              height="100%"
              onReady={() => setVideoReady(true)}
            />
          </div>
        )}
        {projectItems.name === "Tavi" && (
          <div className="page-project__main-image__tavi-gif_wrapper">
            <img
              src={taviGif}
              className="page-project__main-image__tavi-gif"
              alt="gif"
            />
          </div>
        )}
      </div>
      <OtherProjectList />
    </main>
  );
};

export default PageProject;
