// main image in project page
import mindlimbMainImage from '../images/project_images/limb/limb_main.jpg';
import mindlimbMainImageLarge from '../images/project_images/limb/limb_main_large.jpg';
import mindlimbMainImageSmall from '../images/project_images/limb/limb_main_small.jpg';
import mindlimbMainImageMobile from '../images/project_images/limb/limb_main_mobile.jpg'
import mindlimbMainImageMobileLarge from '../images/project_images/limb/limb_main_mobile@2x.jpg'
import mindlimbMainImageMobileSmall from '../images/project_images/limb/limb_main_mobile_small.jpg'
import mindlimbMainImageMobileSmallLarge from '../images/project_images/limb/limb_main_mobile_small@2x.jpg'

import laconicMainImage from '../images/project_images/laconic/laconic_main.jpg';
import laconicMainImageLarge from '../images/project_images/laconic/laconic_main_large.jpg';
import laconicMainImageSmall from '../images/project_images/laconic/laconic_main_small.jpg';
import laconicMainImageMobileSmall from '../images/project_images/laconic/laconic_main_mobile.jpg';
import laconicMainImageMobileSmallLarge from '../images/project_images/laconic/laconic_main_mobile_large.jpg';

import wwfMainImage from '../images/project_images/wwf_back/wwf_main.jpg';
import wwfMainImageLarge from '../images/project_images/wwf_back/wwf_main_large.jpg';
import wwfMainImageSmall from '../images/project_images/wwf_back/wwf_main_small.jpg';
import wwfMainImageMobileSmall from '../images/project_images/wwf_back/wwf_main_mobile_small.jpg';
import wwfMainImageMobileSmallLarge from '../images/project_images/wwf_back/wwf_main_mobile_small@2x.jpg';

import taviMainImage from '../images/project_images/tavi/tavi_main.jpg';
import taviMainImageLarge from '../images/project_images/tavi/tavi_main_large.jpg';
import taviMainImageSmall from '../images/project_images/tavi/tavi_main_small.jpg';
import taviMainImageMobile from '../images/project_images/tavi/tavi_main_mobile.jpg';
import taviMainImageMobileLarge from '../images/project_images/tavi/tavi_main_mobile@2x.jpg';
import taviMainImageMobileSmall from '../images/project_images/tavi/tavi_main_mobile_small.jpg';
import taviMainImageMobileSmallLarge from '../images/project_images/tavi/tavi_main_mobile_small@2x.jpg';

import themebel from '../images/project_images/themebel/themebel_main.svg';
import themebelMainMobile from '../images/project_images/themebel/themebel_main_mobile.svg';
import themebelMainMobileSmall from '../images/project_images/themebel/themebel_main_mobile_small.svg';

import rosgosMainImage from '../images/project_images/rgs/rgs_main.jpg';
import rosgosMainImageLarge from '../images/project_images/rgs/rgs_main_large.jpg';
import rosgosMainImageSmall from '../images/project_images/rgs/rgs_main_small.jpg';
import rosgosMainMobile from '../images/project_images/rgs/rgs_main_mobile.jpg';
import rosgosMainMobileLarge from '../images/project_images/rgs/rgs_main_mobile@2x.jpg';
import rosgosMainMobileSmall from '../images/project_images/rgs/rgs_main_mobile_small.jpg';
import rosgosMainMobileSmallLarge from '../images/project_images/rgs/rgs_main_mobile_small@2x.jpg';

import selectaMainImage from '../images/project_images/selecta/selecta_main.jpg';
import selectaMainImageLarge from '../images/project_images/selecta/selecta_main_large.jpg';
import selectaMainImageSmall from '../images/project_images/selecta/selecta_main_small.jpg';
import selectaMainMobile from '../images/project_images/selecta/selecta_main_mobile.jpg';
import selectaMainMobileLarge from '../images/project_images/selecta/selecta_main_mobile@2x.jpg';
import selectaMainMobileSmall from '../images/project_images/selecta/selecta_main_mobile_small.jpg';
import selectaMainMobileSmallLarge from '../images/project_images/selecta/selecta_main_mobile_small@2x.jpg';

// project image for main page
import limbFull from '../images/project_images/limb/limb_big.jpg';
import limbFullLarge from '../images/project_images/limb/limb_big@2x.jpg';
import limbFull_webp from '../images/project_images/limb/limb_big.webp';
import limbFullLarge_webp from '../images/project_images/limb/limb_big@2x.webp';
import limbMedium from '../images/project_images/limb/limb_medium.jpg';
import limbMediumLarge from '../images/project_images/limb/limb_medium@2x.jpg';
import limbMedium_webp from '../images/project_images/limb/limb_medium.webp';
import limbMediumLarge_webp from '../images/project_images/limb/limb_medium@2x.webp';
import limbCube from '../images/project_images/limb/limb_cube.jpg';
import limbCubeLarge from '../images/project_images/limb/limb_cube@2x.jpg';
import limbCube_webp from '../images/project_images/limb/limb_cube.webp';
import limbCubeLarge_webp from '../images/project_images/limb/limb_cube@2x.webp';
import limbSmall from '../images/project_images/limb/limb_small.jpg';
import limbSmallLarge from '../images/project_images/limb/limb_small@2x.jpg';
import limbSmall_webp from '../images/project_images/limb/limb_small.webp';
import limbSmallLarge_webp from '../images/project_images/limb/limb_small@2x.webp';
import limbMobile from '../images/project_images/limb/limb_mobile.svg';
import limbMobileSmall from '../images/project_images/limb/limb_mobile_small.svg';

import wwfFull from '../images/project_images/wwf_back/wwf_big.jpg';
import wwfFullLarge from '../images/project_images/wwf_back/wwf_big@2x.jpg';
import wwfMedium from '../images/project_images/wwf_back/wwf_medium.jpg';
import wwfMediumLarge from '../images/project_images/wwf_back/wwf_medium@2x.jpg';
import wwfCube from '../images/project_images/wwf_back/wwf_cube.jpg';
import wwfCubeLarge from '../images/project_images/wwf_back/wwf_cube@2x.jpg';
import wwfSmall from '../images/project_images/wwf_back/wwf_small.jpg';
import wwfSmallLarge from '../images/project_images/wwf_back/wwf_small@2x.jpg';
import wwfMobile from '../images/project_images/wwf_back/wwf_mobile.jpg';
import wwfMobileLarge from '../images/project_images/wwf_back/wwf_mobile@2x.jpg';
import wwfMobileSmall from '../images/project_images/wwf_back/wwf_mobile_small.jpg';
import wwfMobileSmallLarge from '../images/project_images/wwf_back/wwf_mobile_small@2x.jpg';

import laconicFull from '../images/project_images/laconic/laconic_big.jpg';
import laconicFullLarge from '../images/project_images/laconic/laconic_big@2x.jpg';
import laconicMedium from '../images/project_images/laconic/laconic_medium.jpg';
import laconicMediumLarge from '../images/project_images/laconic/laconic_medium@2x.jpg';
import laconicSmall from '../images/project_images/laconic/laconic_small.jpg';
import laconicSmallLarge from '../images/project_images/laconic/laconic_small@2x.jpg';
import laconicCube from '../images/project_images/laconic/laconic_cube.jpg';
import laconicCubeLarge from '../images/project_images/laconic/laconic_cube@2x.jpg';
import laconicMobile from '../images/project_images/laconic/laconic_mobile.jpg';
import laconicMobileLarge from '../images/project_images/laconic/laconic_mobile@2x.jpg';
import laconicMobileSmall from '../images/project_images/laconic/laconic_mobile_small.jpg';
import laconicMobileSmallLarge from '../images/project_images/laconic/laconic_mobile_small@2x.jpg';

import taviFull from '../images/project_images/tavi/tavi_big.jpg';
import taviFullLarge from '../images/project_images/tavi/tavi_big@2x.jpg';
import taviMedium from '../images/project_images/tavi/tavi_medium.jpg';
import taviMediumLarge from '../images/project_images/tavi/tavi_medium@2x.jpg';
import taviCube from '../images/project_images/tavi/tavi_cube.jpg';
import taviCubeLarge from '../images/project_images/tavi/tavi_cube@2x.jpg';
import taviSmall from '../images/project_images/tavi/tavi_small.jpg';
import taviSmallLarge from '../images/project_images/tavi/tavi_small@2x.jpg';
import taviMobile from '../images/project_images/tavi/tavi_mobile.jpg';
import taviMobileLarge from '../images/project_images/tavi/tavi_mobile@2x.jpg';
import taviMobileSmall from '../images/project_images/tavi/tavi_mobile_small.jpg';
import taviMobileSmallLarge from '../images/project_images/tavi/tavi_mobile_small@2x.jpg';

import themebelFull from '../images/project_images/themebel/themebel_big.svg';
import themebelMedium from '../images/project_images/themebel/themebel_medium.svg';
import themebelCube from '../images/project_images/themebel/themebel_cube.svg';
import themebelSmall from '../images/project_images/themebel/themebel_small.svg';
import themebelMobile from '../images/project_images/themebel/themebel_mobile.svg';
import themebelMobileSmall from '../images/project_images/themebel/themebel_mobile_small.svg';

import rgsFull from '../images/project_images/rgs/rgs_big.jpg';
import rgsFullLarge from '../images/project_images/rgs/rgs_big@2x.jpg';
import rgsMedium from '../images/project_images/rgs/rgs_medium.jpg';
import rgsMediumLarge from '../images/project_images/rgs/rgs_medium@2x.jpg';
import rgsCube from '../images/project_images/rgs/rgs_cube.jpg';
import rgsCubeLarge from '../images/project_images/rgs/rgs_cube@2x.jpg';
import rgsSmall from '../images/project_images/rgs/rgs_small.jpg';
import rgsSmallLarge from '../images/project_images/rgs/rgs_small@2x.jpg';
import rgsMobile from '../images/project_images/rgs/rgs_mobile.jpg';
import rgsMobileLarge from '../images/project_images/rgs/rgs_mobile@2x.jpg';
import rgsMobileSmall from '../images/project_images/rgs/rgs_mobile_small.jpg';
import rgsMobileSmallLarge from '../images/project_images/rgs/rgs_mobile_small@2x.jpg';

import selectaFull from '../images/project_images/selecta/selecta_big.jpg';
import selectaFullLarge from '../images/project_images/selecta/selecta_big@2x.jpg';
import selectaMedium from '../images/project_images/selecta/selecta_medium.jpg';
import selectaMediumLarge from '../images/project_images/selecta/selecta_medium@2x.jpg';
import selectaCube from '../images/project_images/selecta/selecta_cube.jpg';
import selectaCubeLarge from '../images/project_images/selecta/selecta_cube@2x.jpg';
import selectaSmall from '../images/project_images/selecta/selecta_small.jpg';
import selectaSmallLarge from '../images/project_images/selecta/selecta_small@2x.jpg';
import selectaMobile from '../images/project_images/selecta/selecta_mobile.jpg';
import selectaMobileLarge from '../images/project_images/selecta/selecta_mobile@2x.jpg';
import selectaMobileSmall from '../images/project_images/selecta/selecta_mobile_small.jpg';
import selectaMobileSmallLarge from '../images/project_images/selecta/selecta_mobile_small@2x.jpg';

import ksitestFull from '../images/project_images/ksively/ksivalue_big.jpg';
import ksitestFullLarge from '../images/project_images/ksively/ksivalue_big@2x.jpg';
import ksitestMedium from '../images/project_images/ksively/ksivalue_medium.jpg';
import ksitestMediumLarge from '../images/project_images/ksively/ksivalue_medium@2x.jpg';
import ksitestCube from '../images/project_images/ksively/ksivalue_cube.jpg';
import ksitestCubeLarge from '../images/project_images/ksively/ksivalue_cube@2x.jpg';
import ksitestSmall from '../images/project_images/ksively/ksivalue_small.jpg';
import ksitestSmallLarge from '../images/project_images/ksively/ksivalue_small@2x.jpg';
import ksitestMobile from '../images/project_images/ksively/ksivalue_mobile.jpg';
import ksitestMobileLarge from '../images/project_images/ksively/ksivalue_mobile@2x.jpg';
import ksitestMobileSmall from '../images/project_images/ksively/ksivalue_mobile_small.jpg';
import ksitestMobileSmallLarge from '../images/project_images/ksively/ksivalue_mobile_small@2x.jpg';
import ksitestMain from '../images/project_images/ksively/ksivalue_main.jpg';
import ksitestMainLarge from '../images/project_images/ksively/ksivalue_main_large.jpg';
import ksitestMainMobile from '../images/project_images/ksively/ksivalue_main_mobile.jpg';
import ksitestMainMobileLarge from '../images/project_images/ksively/ksivalue_main_mobile@2x.jpg';
import ksitestMainMobileSmall from '../images/project_images/ksively/ksivalue_main_mobile_small.jpg';
import ksitestMainMobileSmallLarge from '../images/project_images/ksively/ksivalue_main_mobile_small@2x.jpg';

import yeopenFull from '../images/project_images/yeopen/yeopen_big.jpg';
import yeopenFullLarge from '../images/project_images/yeopen/yeopen_big@2x.jpg';
import yeopenMedium from '../images/project_images/yeopen/yeopen_medium.jpg';
import yeopenMediumLarge from '../images/project_images/yeopen/yeopen_medium@2x.jpg';
import yeopenCube from '../images/project_images/yeopen/yeopen_cube.jpg';
import yeopenCubeLarge from '../images/project_images/yeopen/yeopen_cube@2x.jpg';
import yeopenSmall from '../images/project_images/yeopen/yeopen_small.jpg';
import yeopenSmallLarge from '../images/project_images/yeopen/yeopen_small@2x.jpg';
import yeopenMain from '../images/project_images/yeopen/yeopen_main.jpg';
import yeopenMainLarge from '../images/project_images/yeopen/yeopen_main_large.jpg';
import yeopenMobile from '../images/project_images/yeopen/yeopen_mobile.jpg';
import yeopenMobileLarge from '../images/project_images/yeopen/yeopen_mobile@2x.jpg';
import yeopenMobileSmall from '../images/project_images/yeopen/yeopen_mobile_small.jpg';
import yeopenMobileSmallLarge from '../images/project_images/yeopen/yeopen_mobile_small@2x.jpg';
// main images
import yeopenMainMobile from '../images/project_images/yeopen/yeopen_main_mobile.jpg';
import yeopenMainMobileLarge from '../images/project_images/yeopen/yeopen_main_mobile@2x.jpg';
import yeopenMainMobileSmall from '../images/project_images/yeopen/yeopen_main_mobile_small.jpg';
import yeopenMainMobileSmallLarge from '../images/project_images/yeopen/yeopen_main_mobile_small@2x.jpg';


const projectList = [
  {
    id: 'laconic',
    name: 'Laconic',
    description: 'Scan a book to save a quote',
    tags: ['mobile-application', 'layout', 'react-native'],
    gradient: 'linear-gradient(#1C1C23, #1C1C23)',
    link: 'https://laconic.so/',
    linkToApp: {
      appStore: 'https://apps.apple.com/us/app/id1560841729',
    },
    mainImage: {
      normal: laconicMainImage,
      large: laconicMainImageLarge,
      small: laconicMainImageSmall,
    },
    mainImageMobile: {
      image: laconicMainImageMobileSmall,
      imageLarge: laconicMainImageMobileSmallLarge,
      smallImage: laconicMainImageMobileSmall,
      smallImageLarge: laconicMainImageMobileSmallLarge,
    },
    boardImage: [laconicFull, laconicCube, laconicMedium, laconicSmall],
    boardImageLarge: [laconicFullLarge, laconicCubeLarge, laconicMediumLarge, laconicSmallLarge],
    mobileImages: {
      image: laconicMobile,
      imageLarge: laconicMobileLarge,
      smallImage: laconicMobileSmall,
      smallImageLarge: laconicMobileSmallLarge,
    },
  },
  {
    id: 'ksitest',
    name: 'Кситест',
    description: 'Консалтинг, разработка фронтенда проектов компании связанных с биотехнологиями. Визулизация данных, учет результатов генетических исследований, аналитика.',
    boardDescription: 'Визулизация данных, исследования, аналитика.',
    tags: ['data-visualization', 'web-application', 'react', 'redux', 'd3', 'gitlabCi'],
    gradient: 'linear-gradient(90deg, #DBEBFE 25%, #E2E9F1 37%, #DBEBFE 63%)',
    link: 'https://ksitest.ru/',
    mainImage: {
      normal: ksitestMain,
      large: ksitestMainLarge,
      small: ksitestMainMobile,
    },
    mainImageMobile: {
      image: ksitestMainMobile,
      imageLarge: ksitestMainMobileLarge,
      smallImage: ksitestMainMobileSmall,
      smallImageLarge: ksitestMainMobileSmallLarge,
    },
    boardImage: [ksitestFull, ksitestCube, ksitestMedium, ksitestSmall],
    boardImageLarge: [ksitestFullLarge, ksitestCubeLarge, ksitestMediumLarge, ksitestSmallLarge],
    mobileImages: {
      image: ksitestMobile,
      imageLarge: ksitestMobileLarge,
      smallImage: ksitestMobileSmall,
      smallImageLarge: ksitestMobileSmallLarge,
    },
  },
  {
    id: 'yeopen',
    name: 'Yeopen',
    description: 'Социальная сеть для творческих людей',
    tags: ['web-application', 'layout', 'backend', 'angular'],
    gradient: 'linear-gradient(270deg, #E0E8F2 25%, #DEECEA 37%, #E0E8F2 63%)',
    link: 'https://yeopen.com/',
    mainImage: {
      normal: yeopenMain,
      large: yeopenMainLarge,
      small: yeopenMainMobile,
    },
    mainImageMobile: {
      image: yeopenMainMobile,
      imageLarge: yeopenMainMobileLarge,
      smallImage: yeopenMainMobileSmall,
      smallImageLarge: yeopenMainMobileSmallLarge,
    },
    boardImage: [yeopenFull, yeopenCube, yeopenMedium, yeopenSmall],
    boardImageLarge: [yeopenFullLarge, yeopenCubeLarge, yeopenMediumLarge, yeopenSmallLarge],
    mobileImages: {
      image: yeopenMobile,
      imageLarge: yeopenMobileLarge,
      smallImage: yeopenMobileSmall,
      smallImageLarge: yeopenMobileSmallLarge,
    },
  },
  {
    id: 'carouselWWF',
    name: 'Карусель + WWF',
    description: 'Концепт промо-сайта для совместной благотворительной акции',
    boardDescription: 'Концепт промо-сайта для благотворительной акции',
    tags: ['web-application'],
    gradient: 'linear-gradient(87.49deg, #030303 25%, #151618 37%, #030303 63%)',
    link: '',
    mainImage: {
      normal: wwfMainImage,
      large: wwfMainImageLarge,
      small: wwfMainImageSmall,
    },
    mainImageMobile: {
      image: wwfMainImageSmall,
      imageLarge: wwfMainImageSmall,
      smallImage: wwfMainImageMobileSmall,
      smallImageLarge: wwfMainImageMobileSmallLarge,
    },
    videoUrl: 'https://player.vimeo.com/video/390303641',
    boardImage: [wwfFull, wwfCube, wwfMedium, wwfSmall],
    boardImageLarge: [wwfFullLarge, wwfCubeLarge, wwfMediumLarge, wwfSmallLarge],
    mobileImages: {
      image: wwfMobile,
      imageLarge: wwfMobileLarge,
      smallImage: wwfMobileSmall,
      smallImageLarge: wwfMobileSmallLarge,
    },
  },
  {
    id: 'tavi',
    name: 'Tavi',
    description: 'Айдентика и дизайн сайта для онлайн-магазина детских товаров',
    tags: ['web-design','identity'],
    gradient: 'linear-gradient(90.82deg, #FEDAE5 25%, #FFE0DC 37%, #FEDAE5 63%)',
    link: '',
    mainImage: {
      normal: taviMainImage,
      large: taviMainImageLarge,
      small: taviMainImageSmall,
    },
    mainImageMobile: {
      image: taviMainImageMobile,
      imageLarge: taviMainImageMobileLarge,
      smallImage: taviMainImageMobileSmall,
      smallImageLarge: taviMainImageMobileSmallLarge,
    },
    boardImage: [taviFull, taviCube, taviMedium, taviSmall],
    boardImageLarge: [taviFullLarge, taviCubeLarge, taviMediumLarge, taviSmallLarge],
    mobileImages: {
      image: taviMobile,
      imageLarge: taviMobileLarge,
      smallImage: taviMobileSmall,
      smallImageLarge: taviMobileSmallLarge,
    },
  },
  {
    id: 'themebel',
    name: 'Themebel',
    description: 'Дизайн сайта и айдентика для мебельного онлайн-магазина',
    boardDescription: 'Дизайн сайта и айдентика для мебельного магазина',
    tags: ['identity', 'web-application'],
    gradient: 'linear-gradient(91.61deg, #FCEAD0 25%, #FFF3DB 37%, #FCEAD0 63%)',
    link: 'http://themebel.ru/',
    mainImage: {
      normal: themebel,
      large: themebel,
      small: themebel,
    },
    mainImageMobile: {
      image: themebelMainMobile,
      imageLarge: themebelMainMobile,
      smallImage: themebelMainMobileSmall,
      smallImageLarge: themebelMainMobileSmall,
    },
    videoUrl: 'https://player.vimeo.com/video/390149007',
    boardImage: [themebelFull, themebelCube, themebelMedium, themebelSmall],
    boardImageLarge: [themebelFull, themebelCube, themebelMedium, themebelSmall],
    mobileImages: {
      image: themebelMobile,
      imageLarge: themebelMobile,
      smallImage: themebelMobileSmall,
      smallImageLarge: themebelMobileSmall,
    },
  },
  {
    id: 'rosgosstrah',
    name: 'Росгосстрах',
    description: 'Иллюстрации для нового сайта Росгосстрах Жизнь',
    boardDescription: 'Иллюстрации для Росгосстрах Жизнь',
    tags: ['illustrations'],
    gradient: 'linear-gradient(90deg, #F4EFE6 25%, #FDF3E0 37%, #F4EFE6 63%)',
    link: '',
    mainImage: {
      normal: rosgosMainImage,
      large: rosgosMainImageLarge,
      small: rosgosMainImageSmall,
    },
    mainImageMobile: {
      image: rosgosMainMobile,
      imageLarge: rosgosMainMobileLarge,
      smallImage: rosgosMainMobileSmall,
      smallImageLarge: rosgosMainMobileSmallLarge,
    },
    boardImage: [rgsFull, rgsCube, rgsMedium, rgsSmall],
    boardImageLarge: [rgsFullLarge, rgsCubeLarge, rgsMediumLarge, rgsSmallLarge],
    mobileImages: {
      image: rgsMobile,
      imageLarge: rgsMobileLarge,
      smallImage: rgsMobileSmall,
      smallImageLarge: rgsMobileSmallLarge,
    },
  },
  {
    id: 'selecta',
    name: 'Selecta',
    description: 'Иллюстрации для страхового приложения',
    tags: ['illustrations'],
    gradient: 'linear-gradient(113.94deg, #FBFCF6 25%, #F5FFF5 37%, #FBFCF6 63%)',
    link: '',
    mainImage: {
      normal: selectaMainImage,
      large: selectaMainImageLarge,
      small: selectaMainImageSmall,
    },
    mainImageMobile: {
      image: selectaMainMobile,
      imageLarge: selectaMainMobileLarge,
      smallImage: selectaMainMobileSmall,
      smallImageLarge: selectaMainMobileSmallLarge,
    },
    boardImage: [selectaFull, selectaCube, selectaMedium, selectaSmall],
    boardImageLarge: [selectaFullLarge, selectaCubeLarge, selectaMediumLarge, selectaSmallLarge],
    mobileImages: {
      image: selectaMobile,
      imageLarge: selectaMobileLarge,
      smallImage: selectaMobileSmall,
      smallImageLarge: selectaMobileSmallLarge,
    },
  },
  {
    id: 'mindlimb',
    name: 'Mindlimb',
    description: 'Веб-приложения для майндмаппинга',
    tags: ['web-application', 'react'],
    gradient: 'linear-gradient(101.45deg,  #1F2227 25%, #343434 37%, #1F2227 63%)',
    link: '',
    mainImage: {
      normal: mindlimbMainImage,
      large: mindlimbMainImageLarge,
      small: mindlimbMainImageSmall,
    },
    mainImageMobile: {
      image: mindlimbMainImageMobile,
      imageLarge: mindlimbMainImageMobileLarge,
      smallImage: mindlimbMainImageMobileSmall,
      smallImageLarge: mindlimbMainImageMobileSmallLarge,
    },
    boardImage: [limbFull, limbCube, limbMedium, limbSmall],
    boardImageLarge: [limbFullLarge, limbCubeLarge, limbMediumLarge, limbSmallLarge],
    boardImage_webp: [limbFull_webp, limbCube_webp, limbMedium_webp, limbSmall_webp],
    boardImageLarge_webp: [limbFullLarge_webp, limbCubeLarge_webp, limbMediumLarge_webp, limbSmallLarge_webp],
    mobileImages: {
      image: limbMobile,
      imageLarge: limbMobile,
      smallImage: limbMobileSmall,
      smallImageLarge: limbMobileSmall,
    },
  },
]

export default projectList;
