import React, { useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './style.css';
import cn from 'classnames';

const ImageContainer = ({
  image,
  largeImage,
  smallImage,
  mobileImages,
  imageAltName,
  gradient
}) => {
  const [imgLoaded, setImgLoaded] = useState(false);

  const { selectedTag, project } = useParams()

  const fakeSmothLoad = () => {
    setTimeout(() => setImgLoaded(true), 3000)
  }

  useLayoutEffect(() => {
    setImgLoaded(false);
    fakeSmothLoad();
  }, [selectedTag, project])

  const img = (
    <img
      src={smallImage}
      alt={imageAltName}
      className={cn(
        "field-entity__image-container__img",
        {'field-entity__image-container__img_showed': imgLoaded}
      )}
      width="1840"
      height="981"
      loading="lazy"
      decoding="async"
      onLoad={fakeSmothLoad}
    />
  )

  return (
      <picture
        className="field-entity__image-container"
        style={{
          background: gradient,
          backgroundSize: '400% 100%',
          animationPlayState: !imgLoaded ? 'running' : 'paused',
        }}
      >
        <source srcSet={`${image}, ${largeImage} 2x`} media="(min-width: 992px)" />
        <source srcSet={`${mobileImages.image}, ${mobileImages.imageLarge} 2x`} media="(min-width: 660px)" />
        <source srcSet={`${mobileImages.smallImage}, ${mobileImages.smallImageLarge} 2x`} media="(max-width: 659px)" />
        {img}
      </picture>
  )
};

export default ImageContainer;
