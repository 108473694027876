import React from 'react';
import ImageContainer from '../FieldEntity/ImageContainer';
import cn from 'classnames';
import './style.css';
import { Link } from 'react-router-dom';
import { getMainTags } from '../MainPage/SmartMainPage';
// import { useWindowThresholdScroll } from '../hooks/useWindowScroll';

const renderImagesInSecuence = (imagesList, n, small) => {
  if (imagesList === undefined) {
    return null;
  }

  if (small) {
    return imagesList[3];
  }

  if (n <= 1) {
    return imagesList[0];
  } if (n === 4 || n === 5) {
    return imagesList[2];
  } if (n > 5) {
    return imagesList[3];
  }

  return imagesList[1];
};

const mainTags = getMainTags();

const renderTagsFromProject = (currentTags) => {
  const projectTags = () => {

    const newTagsList = mainTags.filter((items) => {

      const find = currentTags.find((tag) => items.id === tag);

      return find !== undefined;
    });
  
    return newTagsList;
  };

  const tagsList = projectTags();

  return (
    <>
      {tagsList.map((tag, i) => {
        // if (i >= 2) return null;

        return (
          <span key={i} className="projects-list__project-tag">{tag.name}</span>
        )
        })}
    </>
  )
};

const ImageBoard = ({ item, n, isMainPage, small }) => {

  return (
    <Link
      to={`/project/${item.id}`}
      className={cn(
        'projects-list__project-link',
        item.id,
        {
          'in-mine-page': isMainPage,
        },
        )}
        key={item.id}
      >
      {item.boardImage !== undefined && (
        <ImageContainer
          image={renderImagesInSecuence(item.boardImage, n, small)}
          largeImage={renderImagesInSecuence(item.boardImageLarge, n, small)}
          smallImage={renderImagesInSecuence(item.boardImage, n, small)}
          mobileImages={item.mobileImages}
          imageAltName={item.name}
          gradient={item.gradient}
        />
      )}
      {/* <div className="project-list__gradient" style={{background: item.gradient}} /> */}
      <ul className="projects-list__project-info">
        <li className="projects-list__item">
          <h4 className="projects-list__project-title">{item.name}</h4>
        </li>
        <li className="projects-list__item">
          <span className="projects-list__project-description">{item.boardDescription || item.description}</span> 
        </li>
        <li className="projects-list__item">
          {renderTagsFromProject(item.tags)}
        </li>
      </ul>
    </Link>
  )
}

const ProjectsList = ({ itemsList, small, mainPage, className }) => {

  return (
    <div className={cn('projects-list', className)}>
      {
        itemsList.map((item, n) => (
          <ImageBoard item={item} n={n} small={small} isMainPage={mainPage} />
        ))
      }
    </div>
  );
};

export default ProjectsList;
