import React from 'react';
import { teamList } from '../WorkingGroup/teamList';
import cn from 'classnames';

const teamlid = teamList[0];

const CallbackForm = ({
  name,
  contact,
  msg,
  onChangeMSG,
  onChangeName,
  onChangeContact,
  submitMessage,
  onShowDropdown,
}) => {

  const isFieldsFilled = !name || !contact || !msg;

  return (
    <div className="dropdown-contact">
      <div className="dropdown-contact__scroll-wrapper">
        <div className="dropdown-contact__title_wrapper">
          <h2 className="dropdown-contact__title dropdown-contact__title_first">
            Напишите нам,
          </h2>
          <div className="dropdown-contact__teamlid">
            <img src={teamlid.photo.jpg} alt="" className="dropdown-contact__teamlid__photo" width="120" height="120" />
            <span className="dropdown-contact__teamlid__info">{teamlid.name}, руководитель разработки</span>
            <span className="dropdown-contact__teamlid__info dropdown-contact__teamlid__info_mobile">Руководитель разработки</span>
          </div>
          <h2 className="dropdown-contact__title hide dropdown-contact__title_last">ответит!</h2>
          {/* <h2 className="dropdown-contact__title mobile dropdown-contact__title_last">ответит вам <span className="dropdown-contact__title__ears">^~^</span></h2> */}
          <span className="dropdown-contact__teamlid-message">Напишите нам, я вам отвечу!</span>
        </div>

        <form className="dropdown-contact__form" onSubmit={submitMessage}>
          <input
            className={
              cn(
                'dropdown-contact__input',
                {
                  'dropdown-contact__input_completed': name !== '',
                }
              )
            }
            type="text"
            name="name"
            placeholder="ваше имя"
            value={name}
            onChange={(e) => onChangeName(e.target.value)}
            required
          />
          <input
            className={
              cn(
                'dropdown-contact__input',
                {
                  'dropdown-contact__input_completed': contact !== '',
                }
              )
            }
            type="text"
            name="contact"
            placeholder="email / телефон"
            value={contact}
            onChange={(e) => onChangeContact(e.target.value)}
            required
          />
          <input
            className={
              cn(
                'dropdown-contact__input',
                {
                  'dropdown-contact__input_completed': msg !== '',
                }
              )
            }
            type="text"
            name="message"
            placeholder="сообщение"
            value={msg}
            onChange={(e) => onChangeMSG(e.target.value)}
            required
          />
          <div className="dropdown-contact__buttons-wrapper">
            <button className="dropdown-contact__buttons-wrapper__submit" type="submit" disabled={isFieldsFilled}>Отправить</button>
            <span className="dropdown-contact__buttons-wrapper__or">или</span>
            <button className="dropdown-contact__buttons-wrapper__cancel" type="button" onClick={onShowDropdown}>В другой раз</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CallbackForm;
