import React from 'react';
import { useParams } from 'react-router-dom';
import './style.css';
import projectList from '../data/projectList';
import ProjectsList from '../ProjectsList';


const OtherProjectList = () => {
  const { project } = useParams();

  const hidedCurrentProject = projectList.filter((item) => item.id !== project);

  const currentProjectList = hidedCurrentProject.slice(0, 6);

  return (
    <div className="other-project">
      <h2 className="other-project__title">Другие проекты</h2>
      <ProjectsList className="other-projects-list" itemsList={currentProjectList} small />
    </div>
  );
};

export default OtherProjectList;
